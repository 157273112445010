/* eslint-disable import/prefer-default-export */

export const CommercialROIFormContent = [
  {
    labelIcon: 'person',
    labelText: 'Number of Technicians',
    inputId: 'tE',
    inputPlaceholder: '#',
  },
  {
    labelIcon: 'person',
    labelText: 'Number of Office Employees',
    inputId: 'oE',
    inputPlaceholder: '#',
  },
  {
    labelIcon: 'eval',
    labelText: 'Estimated Hourly Rate per Technician',
    inputPrefix: '$',
    inputId: 'tHr',
    inputPlaceholder: 'amount',
  },
  {
    labelIcon: 'eval',
    labelText: 'Estimated Hourly Rate per Office Employee',
    inputPrefix: '$',
    inputId: 'oHr',
    inputPlaceholder: '#',
  },
  {
    labelIcon: 'wrench',
    labelText: 'Average Number of Jobs per Month',
    inputId: 'j',
    inputPlaceholder: '#',
  },
  {
    labelIcon: 'wrench',
    labelText: 'Estimated Non-PM Jobs per Month',
    inputId: 'jNpm',
    inputPlaceholder: '#',
  },
  {
    labelIcon: 'money',
    labelText: 'Average Revenue per Job',
    inputPrefix: '$',
    inputId: 'rJ',
    inputPlaceholder: 'amount',
  },
  {
    labelIcon: 'money',
    labelText: 'Estimated % Profit per Job',
    inputPrefix: '%',
    inputId: 'pJ',
    inputPlaceholder: 'percentage',
  },
  {
    labelIcon: 'document',
    labelText: 'Invoices Lost per Month',
    inputId: 'iL',
    inputPlaceholder: '#',
  },
];

export const CommercialROIResultContent = [
  {
    title: 'PILLAR 1',
    subhead: 'Improve Cash Flow & Margins',
    description: 'Reduce the billing cycle, increase profitability.',
    resultId: 'improveCashFlowAndMargins',
    features: [
      {
        title: `Invoice Quickly`,
        description: `Get invoices out faster with material lists, custom mark up tables and custom labor rates the technician can add onsite.`,
      },
      {
        title: `Speed to information`,
        description: `Get technicians in and out of jobs faster with proper information in the field, proper contact information, customer history, arrival information for each customer and location.`,
      },
      {
        title: `Dispatch Effectively`,
        description: `Send the tech that is best suited and closest to the job.`,
      },
      {
        title: `Job Costing`,
        description: `Understand your profitability in real-time with automated job costing on every invoice including, labor pay, labor burden, material costs and PO’s.`,
        except: ['starter'],
      },
      {
        title: `Contract profitability Reports`,
        description: `Understand your profitability on every contract with an automated report that can be run in real-time including, labor pay, labor burden, material costs and PO’s all associated to the customer’s contract so when renewal comes you have all the information needed to make informed decisions`,
        except: ['starter'],
      },
      {
        title: `AR Reports`,
        description: `View automated AR reports including 30,60,90 day cycles for each customer with the ability to send statements in bulk.`,
      },
    ],
  },
  {
    title: 'PILLAR 2',
    subhead: 'Visibility, Control & Accountability',
    description: 'Make the best real-time business decisions with live data.',
    resultId: 'visibilityControlAndAccountability',
    features: [
      {
        title: `GPS tracking`,
        description: `Know exactly where your technicians are in real-time`,
      },
      {
        title: `Automated timesheets`,
        description: `Set custom pay rates for each technician including overtime and commission structures to easily pull payroll.`,
        except: ['starter'],
      },
      {
        title: `Geofencing Technology ( add to Automated timesheets)`,
        description: `Leverage our geofencing technology so technicians don’t need to remember to clock in and out of locations, making timesheet reports extremely accurate. Track idle time, drive time, time spent on the job, time spent running for parts, shop time, etc.`,
        except: ['starter'],
      },
      {
        title: `Automated reports and Custom Reports`,
        description: `Access over 150 reports prebuilt and the ability to customize your own reports. ServiceTitan pulls every metric, now you can make real-time business decisions with accurate information.`,
        except: ['starter'],
      },
      {
        title: `Inventory Management`,
        description: `Know exactly what has been used where so you can replenish your trucks and warehouses as needed`,
        except: ['starter'],
      },
      {
        title: `Forecast Materials for Recurring Services`,
        description: `Save money and buy in bulk for necessary parts used on your recurring services`,
        except: ['starter'],
      },
    ],
  },
  {
    title: 'PILLAR 3',
    subhead: 'Streamline Field to Office Interactions',
    description: 'Use one solution for all data with visibility from the office & field.',
    resultId: 'streamlineFieldToOfficeInteractions',
    features: [
      {
        title: `Call Pop`,
        description: `With our telecom integration, you will have the speed to customer data, when your customer’s profile will automatically populate in your call center when they call in. Get on and off the phone faster with templated scripts to gather important job-specific information.`,
      },
      {
        title: `Gated Workflows`,
        description: `Make fields required in the office and the field, get your whole team using best practices to drive consistency and share all important information on both sides in real-time.`,
      },
      {
        title: `Customer history available in the field`,
        description: `Allow technicians to leverage all of the customer's history out in the field, such as old estimates, recurring services, photos, videos, forms, etc.`,
      },
      {
        title: `Cataloged Equipment History`,
        description: `View each piece of equipment as a separate entity with custom tags, model #. Serial #, forms, photos and all historical services cataloged neatly.`,
      },
    ],
  },
  {
    title: 'PILLAR 4',
    subhead: 'Exceptional Customer Experience',
    description: 'Retain Revenue, Create Loyal Customers.',
    resultId: 'exceptionalCustomerExperience',
    features: [
      {
        title: `Customer Payment Portal`,
        description: `Share a login with your customers so they can view the service history, statements and pay their bill online.`,
      },
      {
        title: `Automated Notifications`,
        description: `Send automated messages to your customers via text or email, confirming your appointments and letting them know when the technician will be arriving.`,
      },
      {
        title: `Manage SLA’s with tags`,
        description: `Use custom tags to note important information about your customer’s needs/SLA requirements for example NTE.`,
      },
      {
        title: `Manage Preventative Maintenances effectively`,
        description: `Manage recurring services in your follow up the tab, get notifications on when appointments are due so no visits fall through the cracks.`,
        except: ['starter'],
      },
    ],
  },
];
