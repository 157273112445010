import React, { useState, useEffect, useContext } from 'react';
import { globalHistory } from '@reach/router';
import { Accordion, AccordionSummary, AccordionDetails, Tab } from '@mui/material';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { STIcon } from 'components/Brand/Icons/STIcon';
import TextField from 'components/Core/TextField';
import useRoiCalcImages from 'hooks/useRoiCalcImages';
import SweetScrollContext from 'utils/SweetScrollContext';
import EmbedForm from '../EmbeddableForm';
import { CommercialROIFormContent, CommercialROIResultContent } from './static';

import { FormContainer } from '../styles';
import { CommercialFormContainer, ROIPackageContainer } from './styles';

const CommercialROIForm = ({ width, height, ...otherProps }) => {
  const roiCalcImages = useRoiCalcImages();
  const { scrollToAnchor } = useContext(SweetScrollContext);
  const icons = {};

  icons.person =
    roiCalcImages
      .filter((imageItem) => imageItem.node.internalName === 'commercial-roi-person-icon')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  icons.eval =
    roiCalcImages
      .filter((imageItem) => imageItem.node.internalName === 'commercial-roi-eval-icon')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  icons.wrench =
    roiCalcImages
      .filter((imageItem) => imageItem.node.internalName === 'commercial-roi-wrench-icon')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  icons.money =
    roiCalcImages
      .filter((imageItem) => imageItem.node.internalName === 'commercial-roi-money-icon')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  icons.document =
    roiCalcImages
      .filter((imageItem) => imageItem.node.internalName === 'commerical-roi-document-icon')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  const [formValues, setFormValues] = useState({
    tE: '',
    oE: '',
    tHr: '',
    oHr: '',
    j: '',
    jNpm: '',
    rJ: '',
    pJ: '',
    iL: '',
  });

  const packageCost = {
    starter: 199,
    essentials: 249,
    works: 299,
  };

  const [isFirstLoad, setFirstLoad] = useState(false);
  const [selPackage, setSelPackage] = useState('');
  const [activeTab, setActiveTab] = useState('1');
  const [activeKey, setActiveKey] = useState({
    pillar1: 0,
    pillar2: 0,
    pillar3: 0,
    pillar4: 0,
  });

  const [isValid, setValid] = useState(false);
  const [isResult, setResult] = useState(false);
  const [formResult, setFormResult] = useState({
    improveCashFlowAndMargins: 0,
    visibilityControlAndAccountability: 0,
    streamlineFieldToOfficeInteractions: 0,
    exceptionalCustomerExperience: 0,
    monthlyStSubscription: 0,
    monthlyRevenueGrowth: 0,
    returnOnInvestment: 0,
    returnOnInvestmentPercent: 0,
  });

  const getUrlState = () => {
    return {
      stPackage: selPackage,
      stPackageValue: packageCost[selPackage],
      ...formValues,
    };
  };

  const getUrlQuery = () => {
    const urlState = getUrlState();
    return Object.keys(urlState).reduce(
      (accumulator, currentValue) => `${accumulator}${currentValue}=${urlState[currentValue]}&`,
      '',
    );
  };

  const getHistoryUrl = () => {
    return `${window.location.origin}${window.location.pathname}?${getUrlQuery()}`;
  };

  const pushHistory = () => history.pushState(getUrlState(), 'roi-calculator', getHistoryUrl());

  const calculateImproveCashFlowAndMargins = (iQ, sI, dE, aR, jC, cPr) => {
    switch (selPackage) {
      case 'starter':
        return iQ + sI + dE + aR;
      default:
        return iQ + sI + dE + jC + cPr + aR;
    }
  };

  const calculateVisibilityControlAndAccountability = (gpsT, aT, arCr, iM, fmRs) => {
    switch (selPackage) {
      case 'starter':
        return gpsT;
      default:
        return gpsT + aT + arCr + iM + fmRs;
    }
  };

  const calculateStreamlineFieldToOfficeInteractions = (cP, gW, chaF, cEh) => {
    return cP + gW + chaF + cEh;
  };

  const calculateExceptionalCustomerExperience = (cPp, aN, mSla, mPm) => {
    switch (selPackage) {
      case 'starter':
        return cPp + aN + mSla;
      default:
        return cPp + aN + mSla + mPm;
    }
  };

  const calculateReturnOnInvestment = (monthlyRevenueGrowth, monthlyStSubscription) =>
    monthlyRevenueGrowth - monthlyStSubscription;

  const calculateReturnOnInvestmentPercent = (monthlyRevenueGrowth, monthlyStSubscription) =>
    Math.round(monthlyRevenueGrowth / monthlyStSubscription - 1);

  const calculateMonthlyRevenueGrowth = (
    iQ,
    sI,
    dE,
    aR,
    gpsT,
    cP,
    gW,
    chaF,
    cEh,
    cPp,
    aN,
    mSla,
    jC,
    cPr,
    aT,
    arCr,
    iM,
    fmRs,
    mPm,
  ) => {
    switch (selPackage) {
      case 'starter':
        return iQ + sI + dE + aR + gpsT + cP + gW + chaF + cEh + cPp + aN + mSla;
      case 'essentials':
      case 'works':
        return (
          iQ +
          sI +
          dE +
          jC +
          cPr +
          aR +
          gpsT +
          aT +
          arCr +
          iM +
          fmRs +
          cP +
          gW +
          chaF +
          cEh +
          cPp +
          aN +
          mSla +
          mPm
        );
      default:
        return 0;
    }
  };

  const prepareNumber = (value) => value.replace(',', '');

  const prepareResult = (value) => value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const calculate = () => {
    const { tE, oE, tHr, oHr, j, jNpm, rJ, pJ, iL } = formValues;

    const iQ = oHr * j * 0.05 + iL * 0.3 * rJ;
    const sI = tHr * j * 0.025;
    const dE = tHr * j * 0.025 + oHr * jNpm * 0.0167;
    const jC = (pJ / 100) * 0.05 * rJ * j;
    const cPr = (pJ / 100) * 0.05 * rJ * j;
    const aR = oE * oHr * 4;

    const gpsT = tHr * j * 0.025;
    const aT = tHr * j * 0.008 + oHr * 16;
    const arCr = (pJ / 100) * 0.05 * rJ * j;
    const iM = (pJ / 100) * 0.05 * rJ * j;
    const fmRs = (pJ / 100) * 0.05 * rJ * j;

    const cP = oHr * j * 0.0083;
    const gW = tHr * j * 0.05 + oHr * j * 0.05;
    const chaF = tHr * j * 0.025;
    const cEh = (pJ / 100) * 0.05 * rJ * j;

    const cPp = oHr * j * 0.2;
    const aN = tHr * (j * 0.05) + oHr * (j * 0.025);
    const mSla = oHr * 5 * oE;
    const mPm = oHr * 5 * oE;

    const monthlyRevenueGrowth = calculateMonthlyRevenueGrowth(
      iQ,
      sI,
      dE,
      aR,
      gpsT,
      cP,
      gW,
      chaF,
      cEh,
      cPp,
      aN,
      mSla,
      jC,
      cPr,
      aT,
      arCr,
      iM,
      fmRs,
      mPm,
    );
    const monthlyStSubscription = tE * packageCost[selPackage];
    const returnOnInvestment = calculateReturnOnInvestment(
      monthlyRevenueGrowth,
      monthlyStSubscription,
    );
    const returnOnInvestmentPercent = calculateReturnOnInvestmentPercent(
      monthlyRevenueGrowth,
      monthlyStSubscription,
    );
    const improveCashFlowAndMargins = calculateImproveCashFlowAndMargins(iQ, sI, dE, aR, jC, cPr);
    const visibilityControlAndAccountability = calculateVisibilityControlAndAccountability(
      gpsT,
      aT,
      arCr,
      iM,
      fmRs,
    );
    const streamlineFieldToOfficeInteractions = calculateStreamlineFieldToOfficeInteractions(
      cP,
      gW,
      chaF,
      cEh,
    );
    const exceptionalCustomerExperience = calculateExceptionalCustomerExperience(
      cPp,
      aN,
      mSla,
      mPm,
    );

    setFormResult({
      improveCashFlowAndMargins: prepareResult(Math.floor(improveCashFlowAndMargins)),
      visibilityControlAndAccountability: prepareResult(
        Math.floor(visibilityControlAndAccountability),
      ),
      streamlineFieldToOfficeInteractions: prepareResult(
        Math.floor(streamlineFieldToOfficeInteractions),
      ),
      exceptionalCustomerExperience: prepareResult(Math.floor(exceptionalCustomerExperience)),
      monthlyStSubscription: prepareResult(Math.floor(monthlyStSubscription)),
      monthlyRevenueGrowth: prepareResult(Math.floor(monthlyRevenueGrowth)),
      returnOnInvestment: prepareResult(Math.floor(returnOnInvestment)),
      returnOnInvestmentPercent: prepareResult(Math.floor(returnOnInvestmentPercent)),
    });
  };

  const onChange = (id, value) => {
    setFormValues({
      ...formValues,
      [id]: prepareNumber(value),
    });
  };

  const checkIsValid = () => {
    const zeroVal = Object.keys(formValues).filter(
      (item) => formValues[item].length === 0 || parseInt(formValues[item]) <= 0,
    );
    return zeroVal.length === 0 && selPackage.length > 0;
  };

  const submit = () => {
    if (checkIsValid()) {
      setResult(true);
      calculate();
      pushHistory();
      setActiveTab('2');

      if (document) {
        scrollToAnchor('#commercial-roi-calc-form');
      }
    }
  };

  useEffect(() => {
    let queryObj = {};

    if (globalHistory?.location?.href) {
      const params = globalHistory.location.href.split('?');

      if (params.length > 1) {
        const queryArray = params[1].split('&');
        queryObj = queryArray.reduce((accumulator, currentValue) => {
          const obj = currentValue.split('=');
          return {
            ...accumulator,
            [obj[0]]: obj[1],
          };
        }, {});
      }
    }

    setFormValues({
      tE: parseFloat(queryObj.tE) || '',
      oE: parseFloat(queryObj.oE) || '',
      tHr: parseFloat(queryObj.tHr) || '',
      oHr: parseFloat(queryObj.oHr) || '',
      j: parseFloat(queryObj.j) || '',
      jNpm: parseFloat(queryObj.jNpm) || '',
      rJ: parseFloat(queryObj.rJ) || '',
      pJ: parseFloat(queryObj.pJ) || '',
      iL: parseFloat(queryObj.iL) || '',
    });

    setSelPackage(queryObj.stPackage || '');

    setFirstLoad(true);
  }, []);

  useEffect(() => {
    if (isFirstLoad && checkIsValid()) {
      submit();
      setFirstLoad(false);
    }
  }, [isFirstLoad]);

  useEffect(() => {
    setValid(checkIsValid());
  }, [formValues, selPackage]);

  return (
    <FormContainer
      isEmbed={otherProps.isEmbed}
      {...otherProps}
      style={{ backgroundColor: 'white', marginTop: 0 }}
    >
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container" style={{ maxWidth: 1252 }}>
          <CommercialFormContainer
            width={width}
            height={height}
            {...otherProps}
            id="commercial-roi-calc-form"
          >
            <div className="commercial-roi-form--hero">
              <h1 className="commercial-roi-form--title">
                <span>ServiceTitan</span> Evaluation
              </h1>
              <p className="commercial-roi-form--sub-title">
                ServiceTitan helps businesses operate off the four pillars below to drive the
                highest efficiency gains leading to increased profitability, decreased cycle times,
                and revenue retention.
              </p>
            </div>

            <TabContext value={activeTab}>
              <TabList onChange={(e, key) => setActiveTab(key)}>
                <Tab label="MY BUSINESS" value="1" />
                <Tab label="REVENUE GROWTH" value="2" />
              </TabList>
              <TabPanel value="1">
                <div className="commercial-roi-form--package-wrapper">
                  <p className="commercial-roi-form--package-title">
                    Choose your ServiceTitan package
                  </p>
                  <div className="commercial-roi-form--package-container">
                    <ROIPackageContainer
                      active={selPackage === 'starter'}
                      data-cost="199"
                      data-package="starter"
                      onClick={() => setSelPackage('starter')}
                    >
                      <p>Starter</p>
                      Monthly $199 per managed technician
                      <div className="checkbox" />
                    </ROIPackageContainer>
                    <ROIPackageContainer
                      active={selPackage === 'essentials'}
                      data-cost="249"
                      data-package="essentials"
                      onClick={() => setSelPackage('essentials')}
                    >
                      <p>Essentials</p>
                      Monthly $249 per managed technician
                      <div className="checkbox" />
                    </ROIPackageContainer>
                    <ROIPackageContainer
                      active={selPackage === 'works'}
                      data-cost="299"
                      data-package="works"
                      onClick={() => setSelPackage('works')}
                    >
                      <p>The Works</p>
                      Monthly $299 per managed technician
                      <div className="checkbox" />
                    </ROIPackageContainer>
                  </div>
                </div>

                <div className="commercial-roi-form--form" data-position="resi roi form">
                  <p className="commercial-roi-form--form-title">
                    By entering the metrics below, we can estimate what feature set would be most
                    beneficial for your business.
                  </p>

                  {CommercialROIFormContent.map((formItem, index) => (
                    <div className="commercial-roi-form--line" key={index}>
                      <div className="commercial-roi-form--line-label">
                        <div className="commercial-roi-form--line-icon">
                          <img src={icons[formItem.labelIcon]} alt={formItem.labelText} />
                        </div>
                        <div className="commercial-roi-form--line-text">{formItem.labelText}</div>
                      </div>
                      <div className="commercial-roi-form--line-input">
                        <div className="commercial-roi-form--line-input-placeholder">
                          {formItem.inputPrefix}
                        </div>
                        <TextField
                          id={formItem.inputId}
                          className="input-text"
                          type="number"
                          placeholder={formItem.inputPlaceholder}
                          handleChange={onChange}
                          values={formValues}
                          showOnlyFormError
                        />
                      </div>
                    </div>
                  ))}

                  <div className="commercial-roi-form--line">
                    <div className="commercial-roi-form--line-text" />
                    <button
                      className={`commercial-roi-form--result-button ${!isValid && 'disabled'}`}
                      {...(!isValid && { disabled: 'disabled' })}
                      onClick={submit}
                    >
                      SEE RESULTS
                    </button>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2" disabled={!isResult}>
                <div className="commercial-roi-form--result-wrapper">
                  <div className="commercial-roi-form--result-title">
                    The following results are based on{' '}
                    <span>
                      {selPackage !== 'works' ? 'the' : ''}{' '}
                      <strong>
                        <span className="capitalize">
                          {selPackage === 'works' ? 'the' : ''} {selPackage}
                        </span>{' '}
                        package
                      </strong>
                    </span>
                  </div>

                  {CommercialROIResultContent.map((resultItem, index) => (
                    <div className="commercial-roi-form--result-item" key={index}>
                      <div className="commercial-roi-form--result-item-row check">
                        <div className="commercial-roi-form--result-item-title">
                          {resultItem.title}
                        </div>
                        <div className="commercial-roi-form--result-item-text">
                          <p className="big">{resultItem.subhead}</p>
                          {resultItem.description}
                        </div>
                        <div className="commercial-roi-form--result-item-result">
                          <div className="commercial-roi-form--result-item-result-total improve-cash-flow-and-margins">
                            {`$${formResult[resultItem.resultId]}`}
                          </div>
                          <div className="commercial-roi-form--result-item-result-text">
                            Revenue growth per month
                          </div>
                        </div>
                      </div>

                      <Accordion
                        onChange={(e, expanded) => {
                          setActiveKey({
                            ...activeKey,
                            [`pillar${index + 1}`]: expanded,
                          });
                        }}
                      >
                        <AccordionSummary
                          id={`pillar${index + 1}`}
                          expandIcon={<STIcon type="icon-down-caret" />}
                        >
                          <span>
                            {activeKey[`pillar${index + 1}`] ? 'Hide Feature' : 'Show Feature'}
                          </span>
                        </AccordionSummary>
                        <AccordionDetails>
                          {resultItem.features.map((featureItem, index1) => (
                            <div
                              className={`commercial-roi-form--result-item-row sub-item ${
                                (!featureItem.except || !featureItem.except.includes(selPackage)) &&
                                'check'
                              }`}
                              key={index1}
                            >
                              <div className="commercial-roi-form--result-item-space" />
                              <div className="commercial-roi-form--result-item-text">
                                <p>
                                  {featureItem.title}
                                  <div className="commercial-roi-form--result-item-result-check" />
                                </p>
                                {featureItem.description}
                              </div>
                              <div className="commercial-roi-form--result-item-result">
                                <div className="commercial-roi-form--result-item-result-check" />
                                <div className="commercial-roi-form--result-item-result-not-check">
                                  <div className="commercial-roi-form--result-item-result-text">
                                    Offered in:
                                    <br />
                                    Essentials and
                                    <br />
                                    The Works
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}

                  <div className="commercial-roi-form--result-total">
                    <div className="commercial-roi-form--result-total-row">
                      <span>Monthly Revenue Growth</span>
                      <span className="monthly-revenue-growth highlight">{`$${formResult.monthlyRevenueGrowth}`}</span>
                    </div>
                    <div className="commercial-roi-form--result-total-row">
                      <span>Monthly ServiceTitan Subscription</span>
                      <span className="monthly-st-subscription">{`$${formResult.monthlyStSubscription}`}</span>
                    </div>
                    <div className="commercial-roi-form--result-total-row">
                      <span>Return on Investment</span>
                      <span className="return-on-investment highlight">{`$${formResult.returnOnInvestment}`}</span>
                      <p>
                        This is{' '}
                        <span className="return-on-investment-percent">{`${formResult.returnOnInvestmentPercent}x`}</span>{' '}
                        return on investment per month
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </CommercialFormContainer>
        </div>
      </div>
    </FormContainer>
  );
};

export default EmbedForm(CommercialROIForm);
