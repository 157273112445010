import { graphql, useStaticQuery } from 'gatsby';

const useRoiCalcImages = () => {
  const { allContentfulImage } = useStaticQuery(graphql`
    query {
      allContentfulImage(
        filter: {
          internalName: {
            in: [
              "commercial-roi-person-icon"
              "commercial-roi-eval-icon"
              "commercial-roi-wrench-icon"
              "commercial-roi-money-icon"
              "commerical-roi-document-icon"
            ]
          }
        }
      ) {
        edges {
          node {
            internalName
            image {
              ...Image
            }
          }
        }
      }
    }
  `);

  return allContentfulImage?.edges;
};

export default useRoiCalcImages;
