import React from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import Hero from 'components/Tools/Hero';
import CommercialROICalcForm from 'components/Tools/CommercialROICalculator';
import { createToolsBreadcrumbs } from './utils/createToolsBreadcrumbs';

const CommercialRoiPage = (props) => {
  const data = props.data.contentfulTool;
  const breadcrumbs = createToolsBreadcrumbs('Commercial ROI Calculator');

  return (
    <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={data.seo} layout="toolbox" {...props}>
      <Hero data={data.hero} noOverlap />
      <CommercialROICalcForm />
    </ToolboxLayout>
  );
};

export default CommercialRoiPage;

export const CommercialRoiPageQuery = graphql`
  query CommercialRoiPageQuery {
    contentfulTool(internalName: { eq: "Commercial ROI Calculator" }) {
      ...Tool
    }
  }
`;
